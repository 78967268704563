import 'core-js/stable';
import { DistributorAppInstance } from '..';
import { DEFAULT_CURRENCY_CODE } from '../defaultCurrency';
import { DEFAULT_LANGUAGE_CODE } from '../defaultLanguage';
import { ApplicationOptionsGlobalization } from '../modules/globalization/types';
import { addStyleProperties } from './addStyleProperties';
import { AppConfiguration } from './appConfigurationSchema';
import {
    APP_CONTAINER_ID,
    CONFIGURATION_FILENAME,
    LOCALIZATION_FILENAME,
    ONE_TICK,
    PLATFORM_PRIMARY_API_URL,
} from './bootstrapConstants';
import { createAppContainer } from './createAppContainer';
import { loadJson } from './loadJson';
import { CustomOptions, LogFromLoader } from './types';
import { parseFeatureFlagOption } from './utils';

const version = __VERSION__;
// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
global.Mews = global.Mews || {};
global.Mews.D = function D(configurationIds: string[], dataBaseUrl: string) {
    const initDistributor = () =>
        global.Mews.Distributor({ configurationIds, openElements: '.distributor' }, null, { dataBaseUrl });
    if (global.document.readyState === 'loading') {
        global.addEventListener('DOMContentLoaded', initDistributor);
    } else {
        initDistributor();
    }
};

global.Mews.Distributor = function Distributor(
    window: Window,
    customOptions: CustomOptions,
    loadCallback: ((distributor: DistributorAppInstance) => void) | null,
    // hidden override options
    {
        dataBaseUrl = PLATFORM_PRIMARY_API_URL,
        appUrl = null,
        configuration = null,
        localization = null,
        assetBaseUrl = null,
    } = {}
) {
    const {
        // app
        isStandalone,
        // settings
        hotelIds,
        configurationIds,
        adultCount,
        childCount,
        gtmContainerId,
        introVideoSrc,
        // features
        hideSpecialRequests,
        showRateCompare,
        competitors,
        // theme
        // @ts-expect-error $TSFixMeError: Initializer provides no value for this binding element and the binding element has no default value.
        theme: { primaryColor } = {},
        // initialState options
        startDate,
        endDate,
        language,
        currency,
        // bootstrap options
        openElements,
        // backwards compatibility options
        hotelId,
    } = customOptions;

    const logsFromLoader: LogFromLoader[] = [];

    const appContainer = createAppContainer(window, openElements, { isStandalone, logFromAppContainer });
    // Appending iframe first, before assigning onLoad method.
    // Ordering here is important (in safari), otherwise onLoad is called twice.
    // NOTE: This fixes a bug when the booking engine was initialized twice, therefore all events and actions were emitted twice.
    // Using setTimeout to address a problem that arises when the script is placed in the <head> tag rather than just before the </body> tag.
    const bodyIsUndefined = window?.document?.body == null;

    window.setTimeout(() => {
        window.document.body.appendChild(appContainer.getElement());
        appContainer.onLoad(loadApp);
    }, ONE_TICK);

    function loadApp() {
        const toLowerCase = (str: string | undefined | null) =>
            str != null ? String.prototype.toLowerCase.call(str) : null;

        const configurationUrl = `${dataBaseUrl}/${CONFIGURATION_FILENAME}`;

        Promise.resolve()
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            .then(async () => configuration || loadJson<AppConfiguration>(configurationUrl))
            .then(async (serverConfiguration) => {
                const { platformApiUrl } = serverConfiguration;
                const localizationUrl = `${platformApiUrl}/${LOCALIZATION_FILENAME}`;
                return Promise.all([
                    Promise.resolve().then(() => serverConfiguration),
                    Promise.resolve().then(
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        async () => localization || loadJson<ApplicationOptionsGlobalization>(localizationUrl)
                    ),
                ]);
            })
            .then(([serverConfiguration, globalization]) => {
                const queryString = new URLSearchParams(window.location.search);
                if (bodyIsUndefined) {
                    logsFromLoader.push({
                        type: 'body-is-undefined-widget-initialization',
                        message: 'Distributor widget is initialized in head tag',
                        data: {
                            urlHref: window.location.href,
                            origin: window.origin,
                            configurationIds,
                            hotelIds,
                        },
                    });
                }
                const queryMewsRedirect = queryString.get('mewsRedirect');

                if (queryMewsRedirect != null) {
                    let redirectURL = null;
                    try {
                        redirectURL = new URL(decodeURIComponent(queryMewsRedirect));
                    } catch (error) {
                        logsFromLoader.push({
                            type: 'mews-redirect-parse-failed',
                            message: 'Could not parse mewsRedirect query parameter',
                            data: { origin: window.location.origin, mewsRedirect: queryString.get('mewsRedirect') },
                        });
                    }
                    if (
                        redirectURL != null &&
                        [...serverConfiguration.platformUrls, window.location.origin].includes(redirectURL.origin)
                    ) {
                        const originURL = new URL(window.location.href);
                        const params = new URLSearchParams(originURL.search);
                        params.delete('mewsRedirect');
                        originURL.search = params.toString();
                        window.history.replaceState(null, '', originURL.toString());

                        window.location = redirectURL.href;
                        return;
                    }
                    logsFromLoader.push({
                        type: 'unautorized-redirect',
                        message: 'Distributor widget tried to redirect outside alowed origins',
                        data: {
                            origin: window.location.origin,
                            redirectURL: redirectURL?.href,
                        },
                    });
                }

                const shouldOpen =
                    queryString.get('mewsDistributorOpened') != null ||
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    (hotelId && queryString.get('mewsEnterpriseId') === hotelId);

                const initialState = {
                    app: {
                        availabilityBlockIdFromQuery: queryString.get('mewsAvailabilityBlockId'),
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        languageCode: queryString.get('language') || language || DEFAULT_LANGUAGE_CODE,
                        languageCodeFromQuery: queryString.get('language'),
                        featureFlagDebug: parseFeatureFlagOption(queryString.get('ffdebug')),
                        currencyCode: Boolean(currency) || DEFAULT_CURRENCY_CODE,
                        currencyCodeFromQuery: queryString.get('currency'),
                        cityIdFromQuery: toLowerCase(queryString.get('mewsCityId')),
                        routeFromQuery: toLowerCase(queryString.get('mewsRoute')),
                        roomFromQuery: toLowerCase(queryString.get('mewsRoom')),
                        hotelFromQuery: toLowerCase(queryString.get('mewsHotel')),
                        startDateFromQuery: toLowerCase(queryString.get('mewsStart')),
                        endDateFromQuery: toLowerCase(queryString.get('mewsEnd')),
                        voucherCodeFromQuery: queryString.get('mewsVoucherCode'),
                        reservationGroupIdFromQuery: queryString.get('mewsReservationGroupId'),
                        reservationIdsFromQuery: queryString.get('mewsReservationIds')?.split(',') || [],
                        categorySortingQuery: queryString.get('mewsSort'),
                        flexibleRatesFilterFromQuery: queryString.has('mewsFreeCancellationOnly')
                            ? queryString.get('mewsFreeCancellationOnly') === 'true'
                            : false,
                        paymentCardIdFromQuery: queryString.get('mewsPaymentCardId'),
                        propertyReturnUrlFromQuery: queryString.get('mewsPropertyReturnUrl'),
                        adultCountFromQuery: queryString.has('mewsAdultCount')
                            ? parseInt(queryString.get('mewsAdultCount') ?? '', 10)
                            : null,
                        childCountFromQuery: queryString.has('mewsChildCount')
                            ? parseInt(queryString.get('mewsChildCount') ?? '', 10)
                            : null,
                    },
                    resourceOrder: {
                        startDate,
                        endDate,
                        promotedServices: [],
                    },
                };

                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                const finalAssetBaseUrl = assetBaseUrl || serverConfiguration.assetBaseUrl;

                let finalHotelIds: string[] = [];
                if (Array.isArray(hotelIds)) {
                    finalHotelIds = hotelIds.map(toLowerCase).filter((id): id is string => Boolean(id));
                } else if (hotelId != null) {
                    const formatedHotelId = toLowerCase(hotelId);
                    if (formatedHotelId != null) {
                        finalHotelIds.push(formatedHotelId);
                    }
                }

                const hotels = finalHotelIds.reduce<Record<string, {}>>((acc, currentId) => {
                    acc[currentId] = {
                        settings: {
                            defaultAdultCount: adultCount,
                            defaultChildCount: childCount,
                        },
                        features: {
                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                            showSpecialRequestsField: !hideSpecialRequests,
                            rateCompare: {
                                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                                isEnabled: !!showRateCompare,
                                competitors,
                            },
                        },
                    };
                    return acc;
                }, {});

                const appOptions = {
                    contextWindow: window,
                    closeApp: appContainer.close,
                    setHtmlLanguage: appContainer.setLanguageCode,
                    configuration: {
                        app: {
                            ...serverConfiguration,
                            assetBaseUrl: finalAssetBaseUrl,
                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                            isStandalone: !!isStandalone,
                        },
                        settings: {
                            hotelIds: finalHotelIds,
                            configurationIds,
                            googleTagManagerContainerId: gtmContainerId,
                            introVideoSrc,
                        },
                        hotels,
                        theme: {
                            primaryColor,
                        },
                    },
                    element: `#${APP_CONTAINER_ID}`,
                    globalization,
                    customOptions,
                    logsFromLoader,
                };
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (isStandalone || shouldOpen) {
                    appContainer.open();
                }
                appContainer.loadReactDevtools();

                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                const finalAppUrl = appUrl || getAppUrl(serverConfiguration);
                // @ts-expect-error appOptions is not assignable to parameter of type ApplicationOptions, initialState is not assignable to parameter of type 'ApplicationInitialState'
                appContainer.loadApp(finalAppUrl, appOptions, initialState, (distributor) => {
                    // wrap opener
                    const { open } = distributor;
                    distributor.open = () => {
                        // Redirect non-https domains to standalone
                        if (!isSecureContextWithFallback()) {
                            const { platformPrimaryUrl } = serverConfiguration;
                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                            const ids = configurationIds || finalHotelIds;
                            const configurationQuery = ids.join(';');
                            window.location.replace(`${platformPrimaryUrl}/distributor/${configurationQuery}`);
                        } else {
                            open();
                            appContainer.open();
                        }
                    };
                    // call custom callback
                    if (typeof loadCallback === 'function') {
                        try {
                            loadCallback(distributor);
                        } catch (e) {
                            console.error(e.stack); // eslint-disable-line no-console
                        }
                    }
                });
            })
            .catch(() => {
                createErrorPage({
                    message: 'Loading of the booking engine has failed',
                    hasTryAgain: true,
                });
            });
    }

    interface GetAppUrlOptions {
        assetBaseUrl: string;
    }

    function getAppUrl({ assetBaseUrl: assetUrl }: GetAppUrlOptions) {
        const appFilename = 'distributor-app.js';
        return `${assetUrl}/${version}/${appFilename}`;
    }

    function isSecureContextWithFallback() {
        // Fallback for browsers without isSecureContext support
        if (typeof window.isSecureContext === 'undefined') {
            return isHttps();
        }
        return window.isSecureContext;
    }

    function isHttps() {
        return window.location.protocol === 'https:';
    }

    interface CreateErrorPageOptions {
        message: string;
        hasTryAgain: boolean;
    }

    function logFromAppContainer({ type, message, data }: LogFromLoader) {
        logsFromLoader.push({
            type,
            message,
            data,
        });
        // eslint-disable-next-line no-console
        console.error(message);
    }

    function createErrorPage({ message, hasTryAgain }: CreateErrorPageOptions) {
        const doc = appContainer.getDocument();
        if (doc != null) {
            doc.body.style.cursor = 'default';
            addStyleProperties(appContainer.getElement(), { opacity: '1' });
            const wrapper = doc.createElement('div');
            wrapper.style.textAlign = 'center';
            const title = doc.createElement('h1');
            title.innerText = message;
            wrapper.appendChild(title);
            const close = doc.createElement('a');
            close.href = '';
            close.innerText = 'Close';
            close.addEventListener('click', (e: Event) => {
                e.preventDefault();
                appContainer.close();
            });
            wrapper.appendChild(close);
            if (hasTryAgain) {
                const reload = doc.createElement('a');
                reload.href = '';
                reload.innerText = 'Try again';
                reload.style.marginLeft = '14px';
                reload.addEventListener('click', (e: Event) => {
                    e.preventDefault();
                    doc.body.innerHTML = '';
                    loadApp();
                });
                wrapper.appendChild(reload);
            }
            doc.body.appendChild(wrapper);
        }
    }
}.bind(null, window);
